import { Component, ViewEncapsulation, NgModule, ElementRef } from '@angular/core';
import { ValidatingComponentBase } from './validating.component.base';
import { CommonModule, NgFor } from '@angular/common';


@Component({
    selector: 'validating-control-container',
    templateUrl: './validating-control-container.component.html',
    styleUrls: ['./validating-control-container.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgFor]
})
export class ValidatingControlContainerComponent extends ValidatingComponentBase {




}


