import { Input, Output, EventEmitter, Directive } from "@angular/core";
import { GqlError } from "src/app/services/graphql.module/model";

@Directive()
export class ValidatingComponentBase {

    errorStrings: Array<string> = new Array<string>();

    @Output() validationErrorEvent: EventEmitter<void> = new EventEmitter<void>();
    @Input() namespace: string;
    @Input() set validationErrors(errors: Array<GqlError>) {
        if (this.namespace == undefined) {
            throw new Error("namespace is undefined. Did you set validationErrors before setting namespace??")
        }
        this.errorStrings = [];
        if (errors != undefined) {
            errors.filter(e => e.source == this.namespace).forEach(e => this.errorStrings.push(e.message));
        }
        if (this.errorStrings.length > 0) {
            this.validationErrorEvent.emit();
        }
    };
}
